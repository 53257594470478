import { ApolloProvider } from "@apollo/client";
import { CssBaseline, ThemeProvider } from "@mui/material";
import React from "react";
import ReactDOM from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { LayoutBase } from "./layout/LayoutBase";
import { router } from "./router";
import { theme } from "./theme";

// Init files
import { apolloClient } from "./init/apollo";
import "./init/dayjs";
import "./init/fonts";
import "./init/sentry";
import "./init/updater";

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  // <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <LayoutBase>
          <RouterProvider router={router} />
        </LayoutBase>
      </ThemeProvider>
    </ApolloProvider>
  // </React.StrictMode>
);
