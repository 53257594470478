import { OnDataOptions, useSubscription } from "@apollo/client";
import { graphql } from "~/gql";
import { SpectrumSubscription } from "~/gql/graphql";

const spectrumSubscription = graphql(`
  subscription Spectrum($projectId: ID!) {
    spectrum(projectId: $projectId) {
      id
      values
      holdValues
    }
  }
`);

export const useSpectrumSubscription = (
  projectId: string,
  onData: (data: OnDataOptions<SpectrumSubscription>) => void
) => {
  const { data, loading, error, restart } = useSubscription(
    spectrumSubscription,
    {
      fetchPolicy: "network-only",
      // shouldResubscribe: true,
      ignoreResults: true,
      onError: (error) => {
        console.error(error);
        restart();
      },
      onData,
      variables: {
        projectId,
      },
    }
  );

  return { data: data?.spectrum };
};
