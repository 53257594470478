import { FC, useEffect, useMemo, useState } from "react";
import { CategoryScale, LinearScale, registerables, Chart } from "chart.js";

Chart.register(CategoryScale, LinearScale, ...registerables);
import {
  Box,
  Grid,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
  Fade,
} from "@mui/material";
import { theme } from "~/theme";
import { useLocalStorage } from "react-use";
import { ProjectDeviceValueUnitType, ProjectLimitType } from "~/gql/graphql";
import { octaveBandFreqCorrections } from "./octaveBandFreqCorrections";
import { round } from "lodash";

import { Chart3D } from "./Chart3D";

const units = {
  DB_A: "a",
  DB_C: "c",
  DB_Z: "z",
};

export type OctaveBandType = "1/1" | "1/3";

export type PanelItemSpectrumChart = {
  id: string;
  data?: {
    values: number[];
    holdValues?: number[];
  } | null;
  limits?: {
    type: ProjectLimitType;
    unitType: ProjectDeviceValueUnitType;
    value: number;
  }[];
};

const calculate1_1OctaveGraphData = (data?: {
  values: number[];
  holdValues?: number[];
}): SpectrumGraphData | null => {
  if (data && data.values && data.values.length >= 36) {
    const count = Math.floor(data.values.length / 3);

    const live = [...Array(count)].map((_, i) => {
      const input1 = data.values[i * 3];
      const input2 = data.values[i * 3 + 1];
      const input3 = data.values[i * 3 + 2];

      return round(
        10 *
          Math.log10(
            Math.pow(10, input1 / 10) +
              Math.pow(10, input2 / 10) +
              Math.pow(10, input3 / 10)
          ),
        1
      );
    });

    const hold: number[] =
      data?.holdValues && data.holdValues.length >= 36
        ? [...Array(count)]
            .map((_, i) => {
              if (data.values.length < i * 3 + 2) {
                return null;
              }

              const input1 = data.holdValues![i * 3];
              const input2 = data.holdValues![i * 3 + 1];
              const input3 = data.holdValues![i * 3 + 2];

              return round(
                10 *
                  Math.log10(
                    Math.pow(10, input1 / 10) +
                      Math.pow(10, input2 / 10) +
                      Math.pow(10, input3 / 10)
                  ),
                1
              );
            })
            .filter((x) => x !== null)
        : null;

    return {
      resolution: "1/1",
      values: {
        live: live.slice(2),
        hold: hold?.slice(2),
      },
    };
  }

  return null;
};

const calculateGraphData = (
  data?: {
    values: number[];
    holdValues?: number[];
  } | null
): SpectrumGraphData[] => {
  if (!data) {
    return [];
  }

  const inputResolution: OctaveBandType =
    data.values.length <= 12 ? "1/1" : "1/3";
  const inputResolutionData = {
    resolution: inputResolution,
    values: {
      live: data.values.slice(inputResolution === "1/3" ? 5 : 2),
      hold: data.holdValues?.slice(inputResolution === "1/3" ? 5 : 2),
    },
  };

  if (inputResolution === "1/3") {
    // 1/3 octave allows for calculation of 1/1 octave based on received values
    const octave1_1Data = calculate1_1OctaveGraphData(data);

    if (octave1_1Data) {
      return [octave1_1Data, inputResolutionData];
    }
  }

  return [inputResolutionData];
};

type SpectrumGraphData = {
  resolution: OctaveBandType;
  values: {
    live: number[];
    hold?: number[];
  };
};

export const PanelItemSpectrumChart: FC<PanelItemSpectrumChart> = ({
  id,
  data,
  limits,
}) => {
  const [selectedUnit, setSelectedUnit] =
    useLocalStorage<ProjectDeviceValueUnitType>(
      `spectrum-unit-type-${id}`,
      "DB_Z"
    );
  const [selectedResolution, setSelectedResolution] =
    useLocalStorage<OctaveBandType>(`spectrum-resolution-${id}`, "1/3");

  const calculatedData = calculateGraphData(data);

  useEffect(() => {
    if (!calculatedData.some((x) => x.resolution === selectedResolution)) {
      setSelectedResolution(calculatedData[0]?.resolution || "1/3");
    }
  }, [calculatedData]);

  const selectedData = calculatedData.find(
    (x) => x.resolution === selectedResolution
  );

  const weightedData = useMemo(
    () =>
      (selectedData?.values?.live || [])
        .map((value, i) => {
          const freqCorrections =
            octaveBandFreqCorrections[selectedResolution!][i];

          if (!freqCorrections) {
            return null;
          }

          const hold = selectedData?.values?.hold?.[i] || 0;
          const correctedValue =
            selectedUnit !== "DB_Z"
              ? roundUp(value + freqCorrections[units[selectedUnit!]])
              : value;
          const correctedHoldValue =
            hold && selectedUnit !== "DB_Z"
              ? roundUp(hold + freqCorrections[units[selectedUnit!]])
              : hold;

          return {
            hertz: freqCorrections.hz,
            value: correctedValue,
            hold: correctedHoldValue,
            unit: selectedUnit,
          };
        })
        .filter((val) => val?.hertz >= 31.5)
        .filter((val) => !!val),
    [selectedData, selectedUnit]
  );

  const visibleLimits =
    limits?.filter((limit) => limit.unitType === selectedUnit) || [];

  return (
    <Grid
      item
      xs={12}
      style={{
        userSelect: "none",
        overflowX: "auto",
      }}
    >
      <Chart3D data={weightedData} limits={visibleLimits} />

      <Fade in={true}>
        <Box
          sx={{
            paddingTop: 0,
            paddingLeft: 1,
            paddingRight: 1,
            paddingBottom: 1,
            color: "grey.300",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <ToggleButtonGroup
            size="small"
            value={selectedUnit}
            exclusive
            // disabled={!hasData}
            onChange={(e, value) => {
              value && setSelectedUnit(value);
            }}
          >
            <ToggleButton
              style={{ textTransform: "none", paddingTop: 4, paddingBottom: 4 }}
              value="DB_A"
            >
              dB(A)
            </ToggleButton>
            <ToggleButton
              style={{ textTransform: "none", paddingTop: 4, paddingBottom: 4 }}
              value="DB_C"
            >
              dB(C)
            </ToggleButton>
            <ToggleButton
              style={{ textTransform: "none", paddingTop: 4, paddingBottom: 4 }}
              value="DB_Z"
            >
              dB(Z)
            </ToggleButton>
          </ToggleButtonGroup>

          <ToggleButtonGroup
            size="small"
            value={selectedResolution}
            exclusive
            disabled={!data}
            onChange={(e, value) => {
              value && setSelectedResolution(value);
            }}
          >
            {(!data ? [{ resolution: "1/1" }] : calculatedData).map(
              ({ resolution }) => (
                <ToggleButton
                  key={resolution}
                  style={{
                    textTransform: "none",
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                  value={resolution}
                >
                  {resolution}
                </ToggleButton>
              )
            )}
          </ToggleButtonGroup>
        </Box>
      </Fade>
    </Grid>
  );
};

const roundUp = (num, precision = 1) => {
  precision = Math.pow(10, precision);
  return Math.ceil(num * precision) / precision;
};
