import { useRef, useEffect, useMemo, FC } from "react";
import * as d3 from "d3";
import { ProjectDeviceValueUnitType } from "~/gql/graphql";
import "./chart.css";

const decaySpeed = 10;

type Chart3DProps = {
  data?: {
    hertz: number;
    value: number;
    hold: number;
    unit: ProjectDeviceValueUnitType | undefined;
    isSelected: boolean;
  }[];
  limits?: number[];
};

const formatHertzTick = (value: string | number) => {
  value = parseFloat(value.toString().replace(/[^0-9.]/g, "")) || 0;
  if (value < 1000) return value.toString();

  const si = [
    { v: 1e18, s: "e" },
    { v: 1e15, s: "p" },
    { v: 1e12, s: "t" },
    { v: 1e9, s: "b" },
    { v: 1e6, s: "m" },
    { v: 1e3, s: "k" },
  ];

  const { v, s } = si.find(({ v }) => value >= v) || si[si.length - 1];

  return (value / v).toFixed(2).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, "$1") + s;
};

const backgroundColors = {
  default: "#566B76",
  orange: "#FF6F00",
  red: "#C62828",
};
export const calculateBackgroundColor = (level: number, limit: number) => {
  if (!limit || !level) return backgroundColors["default"];
  if (level >= limit) return backgroundColors["red"];
  if (level >= limit - 3) return backgroundColors["orange"];

  return backgroundColors["default"];
};

const formatHertzTooltipFormat = Intl.NumberFormat("nl-NL", {
  maximumFractionDigits: 3,
});

export const Chart3D: FC<Chart3DProps> = ({ data = [], limits = [] }) => {
  const svgRef = useRef(null);
  const tooltipRef = useRef(null);

  const width = 700;
  // const height = 350;
  const height = 250;
  const margin = { top: 20, right: 30, bottom: 50, left: 40 };
  const chartWidth = width - margin.left - margin.right;
  const chartHeight = height - margin.top - margin.bottom;

  const xStaticTicks =
    data.length === 10
      ? [31.5, 63, 125, 250, 500, 1000, 2000, 4000, 8000, 16000]
      : [31.5, 63, 125, 250, 500, 1000, 2000, 4000, 8000, 20000];

  const xTicks = data.map((d) => d.hertz);

  const yDomain = [20, 100];

  const x = useMemo(
    () => d3.scaleBand().range([0, chartWidth]).domain(xTicks).padding(0.05),
    [xTicks]
  );

  const y = useMemo(
    () => d3.scaleLinear().domain(yDomain).range([chartHeight, 0]),
    [chartHeight]
  );

  // X and Y axis
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    const g = svg.select(".group");

    // X-axis
    g.select(".x-axis")
      .attr("transform", `translate(0,${chartHeight})`)
      .call(
        d3
          .axisBottom(x)
          .tickValues(xStaticTicks.filter((d) => xTicks.includes(d)))
          .tickFormat((d) => formatHertzTick(d))
      );

    // Y-axis
    g.select(".y-axis").call(
      d3
        .axisLeft(y)
        // .tickValues([20, 40, 60, 80, 100, 120, 140])
        .tickValues([20, 40, 60, 80, 100])
        .tickSize(-width + margin.left + margin.right)
    );
  }, [x, y]);

  // Limits lines
  useEffect(() => {
    const g = d3.select(svgRef.current).select(".limits");

    g.selectAll("*").remove();

    limits.map((limit) => {
      g.append("line")
        .attr("x1", 0)
        .attr("x2", chartWidth)
        .attr("y1", y(limit.value))
        .attr("y2", y(limit.value))
        .attr("class", "limit-line");
    });
  }, [limits]);

  // Data
  useEffect(() => {
    const svg = d3.select(svgRef.current);

    // Tooltip setup
    const tooltip = d3.select(tooltipRef.current);

    svg
      .select(".bars")
      .selectAll(".bar")
      .data(data)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "bar")
            .attr("x", (d) => x(d.hertz))
            .attr("y", y(yDomain[0]))
            .attr("fill", (d) =>
              calculateBackgroundColor(d.value, limits[0]?.value)
            )
            .attr("width", x.bandwidth())
            .attr("height", (d) => Math.max(chartHeight - y(yDomain[0]), 0))
            .on("mouseover", (event, d) => {
              tooltip
                .style("opacity", 1)
                .html(
                  `<strong>${formatHertzTooltipFormat.format(
                    d.hertz
                  )} Hz</strong><br>LIVE: ${d.value}</br>HOLD: ${d.hold}`
                )
                .attr("data-num", d.hertz)
                .style("left", event.pageX + 10 + "px")
                .style("top", event.pageY - 20 + "px");
            })
            .on("mousemove", (event) => {
              tooltip
                .style("left", event.pageX + 10 + "px")
                .style("top", event.pageY - 20 + "px");
            })
            .on("mouseout", () => {
              tooltip.style("opacity", 0);
            })
            .transition()
            .duration(125)
            .attr("height", (d) => Math.max(chartHeight - y(d.value), 0))
            .attr("y", (d) => y(d.value)),
        (update) =>
          update
            .interrupt()
            .transition()
            .duration(125)
            .ease(d3.easePoly)
            .attr("x", (d) => x(d.hertz))
            .attr("y", (d) => y(d.value))
            .attr("fill", (d) =>
              calculateBackgroundColor(d.value, limits[0]?.value)
            )
            .attr("width", x.bandwidth())
            .attr("height", (d) => Math.max(chartHeight - y(d.value), 0))
            .on("end", () => {
              d3.select(this)
                .transition()
                .delay(1250)
                .duration((d) => (d.value / decaySpeed) * 1000)
                .ease(d3.easeLinear)
                .attr("y", y(yDomain[0]))
                .attr("height", Math.max(chartHeight - y(yDomain[0]), 0));
            }),
        (exit) => exit.remove()
      );

    // Update error bars
    svg
      .select(".error-bars")
      .selectAll(".error-bar")
      .data(data)
      .join(
        (enter) =>
          enter
            .append("rect")
            .attr("class", "error-bar")
            .attr("x", (d) => x(d.hertz))
            .attr("y", (d) => y(d.hold))
            .attr("fill", (d) =>
              calculateBackgroundColor(d.hold, limits[0]?.value)
            )
            .attr("width", x.bandwidth())
            .attr("height", 1),
        (update) =>
          update
            .transition()
            .duration(125)
            .attr("x", (d) => x(d.hertz))
            .attr("y", (d) => y(d.hold))
            .attr("fill", (d) =>
              calculateBackgroundColor(d.hold, limits[0]?.value)
            )
            .attr("width", x.bandwidth())
            .attr("height", 1),
        (exit) => exit.remove()
      );

    // Ensure tooltip updates when data changes
    if (tooltip.style("opacity") === "1") {
      const num = +tooltip.attr("data-num") || -1;
      const activeData = data.find((d) => d.hertz === num);

      if (activeData) {
        tooltip.html(
          `<strong>${formatHertzTooltipFormat.format(
            activeData.hertz
          )} Hz</strong><br>LIVE: ${activeData.value}</br>HOLD: ${
            activeData.hold
          }`
        );
      }
    }
  }, [data]);

  return (
    <div style={{ position: "relative" }}>
      <svg
        ref={svgRef}
        viewBox={`0 0 ${width} ${height}`}
        preserveAspectRatio="xMidYMid meet"
        // style={{ width: "100%", height: "auto" }}
      >
        <g
          className="group"
          transform={`translate(${margin.left},${margin.top})`}
        >
          <g className="x-axis" />
          <g className="y-axis" />
          <g className="error-bars" />
          <g className="bars" />
          <g className="limits" />
        </g>
      </svg>
      <div ref={tooltipRef} className="tooltip"></div>
    </div>
  );
};
